<template>
  <div class="page">
    <div class="page-content">
      <div slot="header" class="clearfix">
        <h3 class="title"> 账户信息 </h3>
      </div>
      <el-form class="normal-form" label-width="100px" size="small" data-vv-scope="edit">
        <el-form-item label="姓名" required :error="errorBags.collect('姓名')[0]">
          <el-col :span="12">
            <el-input clearable
              v-model="deptFields.name"
              data-vv-name="姓名"
              v-validate="'required'"
            ></el-input>
          </el-col>
        </el-form-item>
        <el-form-item label="手机号" required :error="errorBags.collect('手机号')[0]">
          <el-col :span="12">
            <el-input clearable
              v-model="deptFields.mobile"
              data-vv-name="手机号"
              v-validate="'mobile'"
            ></el-input>
          </el-col>
        </el-form-item>
        <el-form-item label="登录名" required :error="errorBags.collect('登录名')[0]">
          <el-col :span="7">
            <el-input clearable
              v-model="deptFields.login_name"
              data-vv-name="登录名"
              v-validate="'required'"
            >
            </el-input>
          </el-col>
          <el-col :span="12"><p style="margin-left:15px">登录名仅支持英文字母或数字</p></el-col>
        </el-form-item>
        <el-form-item label="角色" required :error="errorBags.collect('角色')[0]">
          <el-select clearable
            v-model="deptFields.role_id"
            data-vv-name="角色"
            v-validate="'required'"
          >
            <el-option
              v-for="(item, index) in roleList"
              :key="index"
              :label="item.value"
              :value="item.code"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item v-if="deptFields.role_id === 2" label="负责大区" required :error="errorBags.collect('负责大区')[0]">
          <el-select clearable
            v-model="deptFields.relate_region"
            data-vv-name="负责大区"
            multiple
            v-validate="'required'"
          >
            <el-option
              v-for="(item, index) in areaList"
              :key="index"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="状态" required :error="errorBags.collect('状态')[0]">
          <el-select clearable v-model="deptFields.status" data-vv-name="状态" v-validate="'required'">
            <el-option
              v-for="(item, index) in status"
              :key="index"
              :label="item.value"
              :value="item.code"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="addUser">保存</el-button>
          <el-button @click="quit">取消</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import { BAApi } from '@/api'

export default {

  data: () => ({
    deptFields: {
      employee_id: '',
      name: '',
      login_name: '',
      role_id: '',
      relate_region: [],
      mobile: '',
      status: '',
      email: ''
    },
    status: [
      {
        code: 1,
        value: '正常'
      },{
        code: 2,
        value: '禁用'
      }
    ],
    roleList: [
      {
        code: 1,
        value: '管理员'
      },{
        code: 2,
        value: '大区经理'
      },{
        code: 3,
        value: 'BA经理'
      }
    ],
    areaList: [],
    loading: false
  }),
  watch: {
    'deptFields.role_id'(val) {
      if(val === 2) {
        this.getAreaList()
      } else {
        this.deptFields.relate_region = []
      }
    }
  },
  methods: {
    clearFields() {
      this.deptFields = Object.assign({}, {
        employee_id: '',
        name: '',
        relate_region: [],
        login_name: '',
        mobile: '',
        status: 1,
        role_id: ''
      })
    },
    async getEmployee(id) {
      let loading = this.$loading()
      try {
        let data = (await BAApi.getEmployeeById(id)).data
        if (data.code === 0) {
            this.deptFields = Object.assign(this.deptFields, data.data.info)
            this.deptFields.role_id = Number(data.data.info.role_id)
            this.deptFields.relate_region = data.data.relate_region.map(item=>item.id)
            this.deptFields.status = Number(data.data.info.status)
        } else {
          this.$error(data)
        }
      } catch (err) {
        console.error(err)
      } finally {
        loading.close()
      }
    },
    async doAddUser() {
      let loading = this.$loading()
      try {
        let data = (await BAApi.modifyUser(this.deptFields)).data
        if (data.code === 0) {
          this.$validator.reset()
          this.clearFields()
          this.$message.success('编辑用户成功')
          this.$router.push({name: 'employeeList'})
        } else {
          this.$error(data)
        }
      } catch (err) {
        console.error(err)
      } finally {
        loading.close()
      }
    },
    async getAreaList() {
      try {
        let data = (await BAApi.getRegionList()).data
        if (data.code === 0) {
          this.areaList = data.region_list
          console.log(data)
        } else {
          this.$error(data)
        }
      } catch (err) {
        console.error(err)
      }
    },

    quit() {
      window.history.go(-1)
    },

    addUser() {
      this.$validator.validate().then(status => {
        if (status) {
          this.doAddUser()
        } else {
          this.$message.error('请正确填写数据')
        }
      })
    }
  },
  mounted() {
    this.deptFields.employee_id = this.$route.query.id
    this.getEmployee(this.deptFields.employee_id)
  }
}
</script>
<style lang="less">
.box-card{
  .title{
    font-weight: 700;
    font-size: 20px;
  }
}
.normal-form{
  width: 750px !important;
}
</style>
